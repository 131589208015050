import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {RichText, Link} from "prismic-reactjs"
import linkResolver from '../utils/linkResolver'

import './blogCard.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const DynamicBlogList = () => {

  const data = useStaticQuery(graphql`
    query featuredBlogListQuery {
      prismic {
        allPosts(sortBy: meta_firstPublicationDate_DESC, first: 3) {
          edges {
            node {
              title
              body {
                ... on PRISMIC_PostBodyBig_image {
                  type
                  primary {
                    big_image
                  }
                }
              }
              _meta {
                uid
                type
                tags
              }
              _linkType
            }
          }
        }
      }
    }  
  `)

  const articles = data.prismic.allPosts.edges;


  if (!articles) return null;

  return (
    <section className="kb__blog-list kb__blog-list--featured">
      <div className="kb__container--full">
        <div className="kb__grid-center">
          <div className="kb__col-10_md-12_sm-12">
            <div className="kb__grid-center">
              {articles.map((article,i) =>{

                var tagLink = "/blog";

                if(article.node._meta.tags[0] === "Kickbooster News"){
                  tagLink = "/blog/kickbooster-news"
                } else if (article.node._meta.tags[0] === "Crowdfunding Tips"){
                  tagLink = "/blog/crowdfunding-tips"
                } else if (article.node._meta.tags[0] === "Creator's Corner"){
                  tagLink = "/blog/creators-corner"
                } else {
                  
                }

                return(
                  <div key={i} className="kb__col-4_md-6_sm-12">
                    
                      <div className="kb__blog-card">
                        <a href={RichLink(article.node)} title={RichText.asText(article.node.title)}>
                          <img src={i === 0 ? article.node.body[0].primary.big_image.url : article.node.body[0].primary.big_image.thumbnail.url} alt="kb" />
                        </a>

                        <div className="kb__blog-card-info">
                          <a href={RichLink(article.node)} title={RichText.asText(article.node.title)}>
                            <h4>{RichText.asText(article.node.title)}</h4>
                          </a>


                          <a href={tagLink} title="View posts in this category">
                            {article.node._meta.tags}
                          </a>

                        </div>
                        
                      </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DynamicBlogList
import React from 'react'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Features from '../components/features'
import RichTextBlock from '../components/richTextBlock'
import FAQs from '../components/faqs'
import Marketplace from '../components/marketplace'
import SupportCards from '../components/supportCards'
import IconGrid from '../components/iconGrid'
import LinkBlock from '../components/linkBlock'
import DyanmicBlogList from '../components/dynamicBlogList'
import FeaturedBlogList from '../components/featuredBlogList'


export const query = graphql`
{
  prismic {
    allHomepages {
      edges {
        node {
          body {
            ... on PRISMIC_HomepageBodyBanner {
              type
              label
              primary {
                center_layout
                cta_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                cta_text
                darker_shapes
                heading_highlight
                heading_part_1
                heading_part_2
                show_slide_arrow
                sub_heading
              }
            }
            ... on PRISMIC_HomepageBodyFeatures_section {
              type
              label
              primary {
                sub_head
                main_heading
                features_section_heading
                features_section_sub_heading
              }
              fields {
                cta_1_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                }
                shape_background
                scroll_to_card_title
                scroll_card_icon
                feature_title
                feature_image
                feature_blurb
                feature
                cta_2_text
                cta_2_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                cta_1_text
              }
            }
            ... on PRISMIC_HomepageBodyRichtext {
              type
              label
              primary {
                center_layout
                rich_text_cta_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      tags
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                rich_text_cta_link_2 {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      tags
                      type
                      uid
                    }
                  }
                  ... on PRISMIC_Page {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                richtext
                rich_text_title
                rich_text_cta_text
                rich_text_cta_text_2
              }
            }
            ... on PRISMIC_HomepageBodyFaqs {
              type
              label
              fields {
                faq_answer
                faq_question
              }
              primary {
                faq_section_title
              }
            }
            ... on PRISMIC_HomepageBodyMarketplace {
              type
              label
              primary {
                marketplace_title
              }
              fields {
                campaign {
                  ... on PRISMIC_Campaign {
                    campaign_image
                    campaign_blurb
                    campaign_booster_link {
                      ... on PRISMIC__ExternalLink {
                        _linkType
                        url
                      }
                      _linkType
                    }
                    campaign_title
                    campaign_platform_link {
                      ... on PRISMIC__ExternalLink {
                        _linkType
                        url
                      }
                      _linkType
                    }
                    campaign_name
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                }
              }
            }
            ... on PRISMIC_HomepageBodyIcon_grid {
              type
              label
              fields {
                icon_card_description
                icon_card_icon
                icon_card_title
                icon_card_image
                icon_card_link {
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  _linkType
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
              }
              primary {
                icon_grid_title
                cols_desktop
                cols_mobile
                grid_with_gutter
                cols_tablet
                has_border
                has_dropshadow
              }
            }
            ... on PRISMIC_HomepageBodySteps {
              type
              label
              fields {
                step_cta_link {
                  _linkType
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      type
                      uid
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                step_title
                step_text
                step_shape
                step_cta_text
              }
              primary {
                steps_section_title
              }
            }
            ... on PRISMIC_HomepageBodySupport_cards {
              type
              label
            }
            ... on PRISMIC_HomepageBodyLink_block {
              type
              label
              primary {
                link_block_link {
                  ... on PRISMIC_Post {
                    title
                    _meta {
                      uid
                      type
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC_Page {
                    title
                    _meta {
                      type
                      uid
                      tags
                    }
                    _linkType
                  }
                  ... on PRISMIC__ExternalLink {
                    _linkType
                    url
                  }
                }
                link_block_icon
                link_block_text
              }
            }
            ... on PRISMIC_HomepageBodyDynamic_blog_posts {
              type
              label
            }
            ... on PRISMIC_HomepageBodyFeatured_blog_posts {
              type
              label
              fields {
                post {
                  ... on PRISMIC_Post {
                    title
                    _linkType
                    _meta {
                      uid
                      type
                      tags
                    }
                    body {
                      ... on PRISMIC_PostBodyBig_image {
                        type
                        label
                        primary {
                          big_image
                        }
                      }
                    }
                  }
                  _linkType
                }
              }
            }
          }
          title
          meta_title
          meta_description
          meta_image
        }
      }
    }
  }
}


`


export default function Home({ data }) {
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  if (!doc) return null
  if(!doc.node) return null;
  
  const page = doc.node;

  return (
    <Layout>
      <div className="kb__container--full-bleed">
        <SEO title={RichText.asText(page.meta_title)} />

        <SEO 
          title={RichText.asText(page.meta_title)}
          meta={[
            {
              property: `og:image`,
              content: page.meta_image.facebook.url
            },{
              property: `twitter:image`,
              content: page.meta_image.twitter.url
            }
          ]}
        />
          
        {page.body &&

          <div>
            {
              page.body.map((slice,i) => {

                if(slice){
                  
                  if (slice.type === "banner") { 
                    return ( 
                      <Banner key={i} banner={slice.primary} />
                    )
                  } else if (slice.type === "features_section") {
                    return(
                      <Features key={i} features={slice} />
                    )
                  } else if (slice.type === "richtext") {
                    return(
                      <RichTextBlock key={i} richTextBlock={slice} />
                    )
                  } else if (slice.type === "faqs") {
                    return(
                      <FAQs key={i} faqs={slice}/>
                    )
                  } else if (slice.type === "marketplace") {
                    return(
                      <Marketplace key={i} marketplace={slice}>Marketplace</Marketplace>
                    )
                  } else if (slice.type === "steps") {
                    return(
                      <h2 key={i}>Steps</h2>
                    )
                  } else if (slice.type === "support_cards") {
                    return(
                      <SupportCards key={i} />
                    )
                  } else if (slice.type === "icon_grid") {
                    return(
                      <IconGrid key={i} grid={slice}/>
                    )
                  } else if (slice.type === "dynamic_blog_posts") {
                    return(
                      <DyanmicBlogList key={i}/>
                    )
                  } else if (slice.type === "link_block") {
                    return(
                      <LinkBlock key={i} linkblock={slice} />
                    )
                  } else if (slice.type === "featured_blog_posts") {
                    return(
                      <FeaturedBlogList key={i} articles={slice} />
                    )
                  } else {
                    return null
                  }

                //Not a slice
                } else {
                  return null
                }
              })
            }
          </div>

        }

      </div>
    </Layout>
  )
}
import React from "react"
import {RichText, Link} from "prismic-reactjs"
import linkResolver from '../utils/linkResolver'

import './blogCard.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const FeaturedBlogList = (props) => {

  if(!props) return null;


  if (!props.articles) return null;

  const articles = props.articles.fields

  return (
    <section className="kb__blog-list kb__blog-list--featured">
      <div className="kb__container--full">
        <div className="kb__grid-center">
          <div className="kb__col-10_md-12_sm-12">
            <div className="kb__grid-center">
              {articles.map((article,i) =>{

                var tagLink = "/blog";

                if(article.post._meta.tags[0] === "Kickbooster News"){
                  tagLink = "/blog/kickbooster-news"
                } else if (article.post._meta.tags[0] === "Crowdfunding Tips"){
                  tagLink = "/blog/crowdfunding-tips"
                } else if (article.post._meta.tags[0] === "Creator's Corner"){
                  tagLink = "/blog/creators-corner"
                } else {
                  
                }

                return(
                  <div key={i} className="kb__col-4_md-6_sm-12">
                    
                      <div className="kb__blog-card">
                        <a href={RichLink(article.post)} title={RichText.asText(article.post.title)}>
                          <img src={i === 0 ? article.post.body[0].primary.big_image.url : article.post.body[0].primary.big_image.thumbnail.url} alt="kb" />
                        </a>

                        <div className="kb__blog-card-info">
                          <a href={RichLink(article.post)} title={RichText.asText(article.post.title)}>
                            <h4>{RichText.asText(article.post.title)}</h4>
                          </a>


                          <a href={tagLink} title="View posts in this category">
                            {article.post._meta.tags}
                          </a>

                        </div>
                        
                      </div>
                  </div>
                )
              })}
            </div> 
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedBlogList